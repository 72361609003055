const actions = {
  ADD_MEDIA_BEGIN: 'ADD_MEDIA_BEGIN',
  ADD_MEDIA_SUCCESS: 'ADD_MEDIA_SUCCESS',

  GET_MEDIA_BEGIN: 'GET_MEDIA_BEGIN',
  GET_MEDIA_SUCCESS: 'GET_MEDIA_SUCCESS',

  REMOVE_MEDIA_BEGIN: 'REMOVE_MEDIA_BEGIN',
  REMOVE_MEDIA_SUCCESS: 'REMOVE_MEDIA_SUCCESS',

  API_ERROR: 'API_ERROR',

  addMediaBegin: () => ({
    type: actions.ADD_MEDIA_BEGIN,
  }),
  addMediaSuccess: (data) => ({
    type: actions.ADD_MEDIA_SUCCESS,
    data,
  }),
  getMediaBegin: () => ({
    type: actions.GET_MEDIA_BEGIN,
  }),
  getMediaSuccess: (data) => ({
    type: actions.GET_MEDIA_SUCCESS,
    data,
  }),
  removeMediaBegin: () => ({
    type: actions.REMOVE_MEDIA_BEGIN,
  }),
  removeMediaSuccess: (data) => ({
    type: actions.REMOVE_MEDIA_SUCCESS,
    data,
  }),
  apiError: (err) => ({
    type: actions.API_ERROR,
    err,
  }),
};

export default actions;
