import React from "react";
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { getItem } from '../../../utility/localStorageControl';
import { loginAsSuperAdmin } from '../../../redux/users/actionCreator';

export const Impersonate = () => {
    const dispatch = useDispatch();
    const user = getItem('user')
    const { email } = user
    const history = useHistory();
    return (
        <Button onClick={() => {
            dispatch(loginAsSuperAdmin({ token: getItem('secondaryAccessToken') }, history));
        }} className="impersonate" shape="round">
            <div className="impersonateItems" >
                <div >
                    <span className="impersonedLable">Impersoned By</span>
                    <span className="impersonedBy"> {email}</span>
                </div>
                <FeatherIcon icon="power" width='16px' height='16px' color="#5F63F2" />
            </div>
        </Button>
    )
}