const actions = {
  INVITE_BEGIN: "INVITE_BEGIN",
  INVITE_SUCCESS: "INVITE_SUCCESS",
  FORGET_PASSWORD_BEGIN: "FORGET_PASSWORD_BEGIN",
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
  GET_ADMIN_BEGIN: "GET_ADMIN_BEGIN",
  GET_ADMIN_SUCCESS: "GET_ADMIN_SUCCESS",
  RESET_API_ERROR: "RESET_API_ERROR",
  API_ERROR: "API_ERROR",
  LOGIN_BEGIN: "LOGIN_BEGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  SIGNUP_BEGIN: "LOGIN_BEGIN",
  SIGNUP_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_BEGIN: "LOGOUT_BEGIN",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LIST_USERS_BEGIN: "LIST_USERS_BEGIN",
  LIST_USERS_SUCCESS: "LIST_USERS_SUCCESS",
  UPDATE_PASSWORD_BEGIN: "UPDATE_PASSWORD_BEGIN",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_USER_PLAN_BEGIN: "UPDATE_USER_PLAN_BEGIN",
  UPDATE_USER_PLAN_SUCCESS: "UPDATE_USER_PLAN_SUCCESS",
  REMOVE_USER_BEGIN: "REMOVE_USER_BEGIN",
  REMOVE_USER_SUCCESS: "REMOVE_USER_SUCCESS",
  UPDATE_USER_BEGIN: "UPDATE_USER_BEGIN",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  ADD_SUB_USER_BEGIN: "ADD_SUB_USER_BEGIN",
  ADD_SUB_USER_SUCCESS: "ADD_SUB_USER_SUCCESS",
  FILTER_USERS_BEGIN: "FILTER_USERS_BEGIN",
  FILTER_USERS_SUCCESS: "FILTER_USERS_SUCCESS",
  LOGIN_AS_ADMIN_BEGIN: 'LOGIN_AS_ADMIN_BEGIN',
  LOGIN_AS_ADMIN_SUCCESS: 'LOGIN_AS_ADMIN_SUCCESS',
  inviteSuccess: () => ({
    type: actions.INVITE_SUCCESS,
  }),
  inviteBegin: () => ({
    type: actions.INVITE_BEGIN,
  }),
  forgetPasswordBegin: () => ({
    type: actions.FORGET_PASSWORD_BEGIN,
  }),
  forgetPasswordSuccess: (data) => ({
    type: actions.FORGET_PASSWORD_SUCCESS,
    data,
  }),
  getAdminBegin: () => ({
    type: actions.GET_ADMIN_BEGIN,
  }),
  getAdminSuccess: (data) => ({
    type: actions.GET_ADMIN_SUCCESS,
    data,
  }),
  loginBegin: () => ({
    type: actions.LOGIN_BEGIN,
  }),
  loginSuccess: (data) => ({
    type: actions.LOGIN_SUCCESS,
    data,
  }),
  signUpBegin: () => ({
    type: actions.SIGNUP_BEGIN,
  }),
  signUpSuccess: (data) => ({
    type: actions.SIGNUP_SUCCESS,
    data,
  }),
  logoutBegin: () => ({
    type: actions.LOGOUT_BEGIN,
  }),
  logoutSuccess: (data) => ({
    type: actions.LOGOUT_SUCCESS,
    data,
  }),
  listUsersBegin: () => ({
    type: actions.LIST_USERS_BEGIN,
  }),
  listUsersSuccess: (data) => ({
    type: actions.LIST_USERS_SUCCESS,
    data,
  }),
  updatePasswordBegin: () => ({
    type: actions.UPDATE_PASSWORD_BEGIN,
  }),
  updatePasswordSuccess: (data) => ({
    type: actions.UPDATE_PASSWORD_SUCCESS,
    data,
  }),
  updateUserPlanBegin: () => ({
    type: actions.UPDATE_USER_PLAN_BEGIN,
  }),
  updateUserPlanSuccess: (data) => ({
    type: actions.UPDATE_USER_PLAN_SUCCESS,
    data,
  }),
  removeUserBegin: () => ({
    type: actions.REMOVE_USER_BEGIN,
  }),
  removeUserSuccess: (data) => ({
    type: actions.REMOVE_USER_SUCCESS,
    data,
  }),
  updateUserBegin: () => ({
    type: actions.UPDATE_USER_BEGIN,
  }),
  updateUserSuccess: (data) => ({
    type: actions.UPDATE_USER_SUCCESS,
    data,
  }),
  apiError: (err) => ({
    type: actions.API_ERROR,
    err,
  }),
  resetAPIError: () => ({
    type: actions.RESET_API_ERROR,
  }),
  addSubUserBegin: () => ({
    type: actions.ADD_SUB_USER_BEGIN,
  }),
  addSubUserSuccess: () => ({
    type: actions.ADD_SUB_USER_SUCCESS,
  }),
  filterUsersBegin: () => ({
    type: actions.FILTER_USERS_BEGIN,
  }),
  filterUsersSuccess: (data) => ({
    type: actions.FILTER_USERS_SUCCESS,
    data,
  }),
  loginAsAdminBegin: () => ({
    type: actions.LOGIN_AS_ADMIN_BEGIN,
  }),
  loginAsAdminSuccess: () => ({
    type: actions.LOGIN_AS_ADMIN_SUCCESS,
  }),
};

export default actions;
