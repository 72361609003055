import Cookies from "js-cookie";
import actions from "./actions";
import DataService from "../../config/dataService/dataService";
import API from "../../config/api/index";
import { isSuccess } from "../../utility/common";
import {
  setItem,
  getItem,
  cleanLocalStorageAndCookies,
} from "../../utility/localStorageControl";

const {
  forgetPasswordBegin,
  forgetPasswordSuccess,
  loginBegin,
  loginSuccess,
  signUpBegin,
  signUpSuccess,
  logoutBegin,
  logoutSuccess,
  apiError,
  resetAPIError,
  updatePasswordBegin,
  updatePasswordSuccess,
} = actions;

const loginUserPSI = (payload, history) => async (dispatch) => {
  try {
    dispatch(loginBegin());
    const response = await DataService.post(API.psi.login, payload);
    const { data } = response;
    if (isSuccess(response)) {
      Cookies.set("logedIn", true);
      setItem("user", data?.user);
      setItem("accessToken", data?.token.token);
      dispatch(loginSuccess(data));
      const { isAdmin } = getItem("user");
      if (isAdmin) {
        history.push("/services");
      } else {
        history.push("/customerDashboard");
      }
    } else {
      dispatch(apiError(response.data));
    }
  } catch (err) {
    dispatch(apiError(err));
  }
};

const SignUpUserPSI = (payload) => async (dispatch) => {
  try {
    dispatch(signUpBegin());
    const response = await DataService.post(API.psi.signUp, payload);
    const { data } = response;
    if (isSuccess(response)) {
      Cookies.set("logedIn", true);
      setItem("user", data?.user);
      setItem("accessToken", data?.token.token);
      dispatch(signUpSuccess(data));
      return response;
    }
    dispatch(apiError(response.data));
    return response;
  } catch (err) {
    dispatch(apiError(err));
  }
};

const logOutPSI = (history) => async (dispatch) => {
  try {
    dispatch(logoutBegin());
    dispatch(logoutSuccess(null));
    cleanLocalStorageAndCookies();
    history.push("/psi-west/login")
  } catch (err) {
    dispatch(apiError(err?.detail || err));
  }
};

const forgetPasswordPSI = (payload) => async (dispatch) => {
  try {
    dispatch(forgetPasswordBegin());
    const response = await DataService.post(API.psi.forgetPassword, payload);
    const { data } = response;
    if (isSuccess(response)) {
      dispatch(forgetPasswordSuccess(data));
      return response;
    }
    dispatch(apiError(response.data));
    return response;
  } catch (err) {
    dispatch(apiError(err?.detail || err));
  }
};

const updatePasswordPSI = (payload) => async (dispatch) => {
  try {
    dispatch(updatePasswordBegin());
    const response = await DataService.post(API.psi.updatePassword, payload);
    const { data } = response;
    if (isSuccess(response)) {
      dispatch(updatePasswordSuccess(data));
      return response;
    }
    dispatch(apiError(response.data));
    return response;
  } catch (err) {
    dispatch(apiError(err?.detail || err));
  }
};

const resettingPSIError = () => async (dispatch) => dispatch(resetAPIError())

export {
  SignUpUserPSI,
  loginUserPSI,
  logOutPSI,
  forgetPasswordPSI,
  updatePasswordPSI,
  resettingPSIError
}