import actions from './actions';

const {
    COUNT_BEGIN,
    COUNT_SUCCESS,
    API_ERROR,
} = actions;

const initState = {
    count: {},
    total: 0,
    loading: false,
    spinLoading: false,
    apiError: false,
    errorMessage: '',
};

const dashboardReducer = (state = initState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case COUNT_BEGIN:
            return {
                ...state,
                spinLoading: true,
            };
        case COUNT_SUCCESS:
            return {
                ...state,
                apiError: false,
                count: data,
                errorMessage: 'success',
                spinLoading: false,
            };
        case API_ERROR:
            return {
                ...state,
                apiError: err.apiError,
                errorMessage: err.message,
                loading: false,
                spinLoading: false,
            };
        default:
            return state;
    }
};

export default dashboardReducer;
