import React from 'react';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <>
        <WraperContent />
      </>
    );
  };
};

export default AuthLayout;
