import { combineReducers } from 'redux';
import { getConversation } from './chat/reducers';
import CompanyInfoReducer from './company-info/reducers';
import CustomerListReducer from './customerListing/reducers';
import EmailReducer from './email/reducers';
import HeaderSearchReducer from './headerSearch/reducers';
import InvoiceReducers from './invoice/reducers';
import MachineRequestReducer from './machines/reducers';
import MediaReducer from './media/reducers';
import ReadMessageReducer from './message/reducers';
import ReadNotificationReducer from './notification/reducers';
import ServiceReportReducer from './serviceReport/reducers';
import ServiceRequestReducer from './serviceRequests/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import UploadReducer from './uploadFiles/reducers';
import UserReducer from './users/reducers';
import dashboardReducer from './dashboard/reducers';
import psiUserReducer from './auth/reducers';

const rootReducers = combineReducers({
  chat: getConversation,
  company: CompanyInfoReducer,
  customerList: CustomerListReducer,
  headerSearchData: HeaderSearchReducer,
  invoice: InvoiceReducers,
  machine: MachineRequestReducer,
  media: MediaReducer,
  message: ReadMessageReducer,
  email: EmailReducer,
  notification: ReadNotificationReducer,
  serviceReport: ServiceReportReducer,
  serviceRequest: ServiceRequestReducer,
  ChangeLayoutMode,
  upload: UploadReducer,
  users: UserReducer,
  dashboard: dashboardReducer,
  psi: psiUserReducer
});

export default rootReducers;
