import Cookies from "js-cookie";
import actions from "./actions";
import DataService from "../../config/dataService/dataService";
import API from "../../config/api/index";
import { isSuccess } from "../../utility/common";
import {
  setItem,
  getItem,
  cleanLocalStorageAndCookies,
} from "../../utility/localStorageControl";

const {
  inviteBegin,
  inviteSuccess,
  forgetPasswordBegin,
  forgetPasswordSuccess,
  getAdminBegin,
  getAdminSuccess,
  loginBegin,
  loginSuccess,
  signUpBegin,
  signUpSuccess,
  logoutBegin,
  logoutSuccess,
  apiError,
  resetAPIError,
  listUsersBegin,
  listUsersSuccess,
  updatePasswordBegin,
  updatePasswordSuccess,
  updateUserPlanBegin,
  updateUserPlanSuccess,
  removeUserBegin,
  removeUserSuccess,
  updateUserBegin,
  updateUserSuccess,
  addSubUserBegin,
  addSubUserSuccess,
  filterUsersBegin,
  filterUsersSuccess,
  loginAsAdminBegin,
  loginAsAdminSuccess
} = actions;

const inviteUser = (payload) => async (dispatch) => {
  try {
    dispatch(inviteBegin());
    const response = await DataService.post(API.auth.invite, payload);
    const responsedData = response.data;
    if (isSuccess(response)) {
      dispatch(inviteSuccess());
    } else {
      dispatch(apiError(responsedData));
    }
    return response;
  } catch (err) {
    dispatch(apiError(err));
  }
};
const loginUser = (payload, history) => async (dispatch) => {
  try {
    dispatch(loginBegin());
    const response = await DataService.post(API.auth.login, payload);
    const responsedData = response.data;
    if (isSuccess(response)) {
      Cookies.set("logedIn", true);
      setItem("user", responsedData.user);
      setItem("accessToken", responsedData.token.token);
      dispatch(loginSuccess(responsedData));
      const { isAdmin } = getItem("user");
      if (isAdmin) {
        history.push("/services");
      } else {
        history.push("/customerDashboard");
      }
    } else {
      dispatch(apiError(response.data));
    }
  } catch (err) {
    dispatch(apiError(err));
  }
};
const resetingAPIError = () => async (dispatch) => {
  dispatch(resetAPIError());
};
const listUser = (payload) => async (dispatch) => {
  const {
    pagination: { pageNumber, nPerPage },
    filters: obj,
    emailFilter
  } = payload;
  try {
    dispatch(listUsersBegin());
    const filter = obj ? JSON.stringify(obj) : [];
    const url = `${API.auth.list}/${nPerPage}/${pageNumber}?filters=${filter}&emailFilter=${emailFilter}`;
    const response = await DataService.post(url);
    const responsedData = response.data;
    if (isSuccess(response)) {
      dispatch(listUsersSuccess(responsedData));
    } else {
      dispatch(apiError(response.data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

const SignUpUser = (payload) => async (dispatch) => {
  try {
    dispatch(signUpBegin());
    const response = await DataService.post(API.auth.signUp, payload);
    const responsedData = response.data;
    if (isSuccess(response)) {
      Cookies.set("logedIn", true);
      setItem("user", responsedData.user);
      setItem("accessToken", responsedData.token.token);
      dispatch(signUpSuccess(responsedData));
      return response;
    }
    dispatch(apiError(response.data));
    return response;
  } catch (err) {
    dispatch(apiError(err));
  }
};
const Register = (payload) => async (dispatch) => {
  try {
    dispatch(signUpBegin());
    const response = await DataService.post(API.auth.register, payload);
    const responsedData = response.data;
    if (isSuccess(response)) {
      Cookies.set("logedIn", true);
      setItem("user", responsedData.user);
      setItem("accessToken", responsedData.token.token);
      dispatch(signUpSuccess(responsedData));
      return response;
    }
    dispatch(apiError(response.data));
    return response;
  } catch (err) {
    dispatch(apiError(err));
  }
};
const logOut = () => async (dispatch) => {
  try {
    dispatch(logoutBegin());
    dispatch(logoutSuccess(null));
    cleanLocalStorageAndCookies();
  } catch (err) {
    dispatch(apiError(err?.detail || err));
  }
};
const getAdmin = () => async (dispatch) => {
  try {
    dispatch(getAdminBegin());
    const response = await DataService.get(API.auth.getAdmin);
    const responsedData = response.data;
    if (isSuccess(response)) {
      dispatch(getAdminSuccess(responsedData));
    }
  } catch (err) {
    dispatch(apiError(err?.detail || err));
  }
};

const forgetPassword = (payload) => async (dispatch) => {
  try {
    dispatch(forgetPasswordBegin());
    const response = await DataService.post(API.auth.forgetPassword, payload);
    const responsedData = response.data;
    if (isSuccess(response)) {
      dispatch(forgetPasswordSuccess(responsedData));
      return response;
    }
    dispatch(apiError(response.data));
    return response;
  } catch (err) {
    dispatch(apiError(err?.detail || err));
  }
};

const updatePassword = (payload) => async (dispatch) => {
  try {
    dispatch(updatePasswordBegin());
    const response = await DataService.post(API.auth.updatePassword, payload);
    const responsedData = response.data;
    if (isSuccess(response)) {
      dispatch(updatePasswordSuccess(responsedData));
      return response;
    }
    dispatch(apiError(response.data));
    return response;
  } catch (err) {
    dispatch(apiError(err?.detail || err));
  }
};
const updateUserPlan = (payload) => async (dispatch) => {
  try {
    dispatch(updateUserPlanBegin());
    const response = await DataService.post(API.auth.updateUserPlan, payload);
    const responsedData = response.data;
    if (isSuccess(response)) {
      setItem("user", responsedData.user);
      dispatch(updateUserPlanSuccess(responsedData));
      return response;
    }
    dispatch(apiError(response.data));
    return response;
  } catch (err) {
    dispatch(apiError(err?.detail || err));
  }
};
const removeUser = (payload) => async (dispatch) => {
  try {
    dispatch(removeUserBegin());
    const url = `${API.auth.removeUser}/${payload}`;
    const response = await DataService.delete(url);
    if (isSuccess(response)) {
      dispatch(removeUserSuccess(response.data));
      return response;
    }
    dispatch(apiError(response.data));
    return response;
  } catch (err) {
    dispatch(apiError(err));
  }
};

const editUser = (payload) => async (dispatch) => {
  const { id, data } = payload;
  try {
    dispatch(updateUserBegin());
    const url = `${API.auth.editUser}/${id}`;
    const response = await DataService.post(url, data);
    if (isSuccess(response)) {
      dispatch(updateUserSuccess(response.data));
      return response;
    }
    dispatch(apiError(response.data));
  } catch (error) {
    dispatch(apiError(error));
  }
};
const createRole = (payload) => async (dispatch) => {
  try {
    dispatch(inviteBegin());
    const response = await DataService.post(API.auth.createRole, payload);
    const responsedData = response.data;
    if (isSuccess(response)) {
      dispatch(inviteSuccess());
    } else {
      dispatch(apiError(responsedData));
    }
    return response;
  } catch (err) {
    dispatch(apiError(err));
  }
};
const listRoles = (payload) => async (dispatch) => {
  const {
    pagination: { pageNumber, nPerPage },
    filters: obj,
  } = payload;
  try {
    dispatch(listUsersBegin());
    const filter = obj ? JSON.stringify(obj) : [];
    const url = `${API.auth.listRoles}/${nPerPage}/${pageNumber}?filters=${filter}`;
    const response = await DataService.post(url);
    const responsedData = response.data;
    if (isSuccess(response)) {
      dispatch(listUsersSuccess(responsedData));
    } else {
      dispatch(apiError(response.data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

const addSubUser = (payload) => async (dispatch) => {
  try {
    dispatch(addSubUserBegin());
    const response = await DataService.post(API.auth.addSubUser, payload);
    const responsedData = response.data;
    if (isSuccess(response)) {
      dispatch(addSubUserSuccess());
    } else {
      dispatch(apiError(responsedData));
    }
    return response;
  } catch (err) {
    dispatch(apiError(err));
  }
};
const filterUsers = (payload) => async (dispatch) => {
  const {
    pagination: { pageNumber, nPerPage },
    filters: obj,
    otherFilters: filerObject
  } = payload;
  try {
    dispatch(filterUsersBegin());
    const filter = obj ? JSON.stringify(obj) : [];
    const other = filerObject ? JSON.stringify(filerObject) : []
    const url = `${API.auth.filterUsers}/${nPerPage}/${pageNumber}?filters=${filter}&otherFilters=${other}`;
    const response = await DataService.post(url);
    const responsedData = response.data;
    if (isSuccess(response)) {
      dispatch(filterUsersSuccess(responsedData));
    } else {
      dispatch(apiError(response.data));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};
const loginAsAdmin = (payload, history) => async (dispatch) => {
  try {
    dispatch(loginAsAdminBegin());
    const response = await DataService.post(API.auth.loginAsAdmin, payload);
    const responsedData = response.data;
    if (isSuccess(response)) {
      Cookies.set("logedIn", true);
      const secondaryUser = getItem('user');
      const secondaryAccessToken = getItem("accessToken")
      setItem("user", responsedData.user);
      setItem("accessToken", responsedData.token.token);
      setItem('secondaryUser', secondaryUser);
      setItem('secondaryAccessToken', secondaryAccessToken);
      dispatch(loginAsAdminSuccess(responsedData));
      const { isAdmin } = getItem("user");
      if (isAdmin) {
        history.push("/services");
      } else {
        history.push("/customerDashboard");
      }
    } else {
      dispatch(apiError(response.data));
    }
  } catch (err) {
    dispatch(apiError(err));
  }
};
const loginAsSuperAdmin = (payload, history) => async (dispatch) => {
  try {
    dispatch(loginAsAdminBegin());
    const response = await DataService.post(API.auth.loginAsSuperAdmin, payload);
    const responsedData = response.data;
    if (isSuccess(response)) {
      cleanLocalStorageAndCookies()
      Cookies.set("logedIn", true);
      setItem("user", responsedData.user);
      setItem("accessToken", responsedData.token.token);
      dispatch(loginAsAdminSuccess(responsedData));
      const { isAdmin } = getItem("user");
      if (isAdmin) {
        history.push("/services");
      } else {
        history.push("/customerDashboard");
      }
    } else {
      dispatch(apiError(response.data));
    }
  } catch (err) {
    dispatch(apiError(err));
  }
};
export {
  inviteUser,
  resetingAPIError,
  loginUser,
  listUser,
  SignUpUser,
  logOut,
  getAdmin,
  forgetPassword,
  updatePassword,
  updateUserPlan,
  removeUser,
  editUser,
  addSubUser,
  filterUsers,
  loginAsAdmin,
  loginAsSuperAdmin,
  Register,
  createRole,
  listRoles
};
