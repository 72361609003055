import React, { lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";

const SuperAdminDashboard = lazy(() =>
  import("../../container/superAdminDashboard/index")
);
const Admin = lazy(() => import("../../container/admin"));
const SuperAdminUsers = lazy(() =>
  import("../../container/setting/component/superAdminUsers")
);
const NotFound = () => <Redirect to="/" />;
// const NotFound = lazy(() => import("../../container/pages/404"));

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/`} component={SuperAdminDashboard} />
      <Route
        exact
        path={`${path}superAdminDashboard`}
        component={SuperAdminDashboard}
      />
      <Route exact path={`${path}admin`} component={Admin} />
      <Route exact path={`${path}setting/users`} component={SuperAdminUsers} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default DashboardRoutes;
