/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const API = {
  auth: {
    login: "users/login-user",
    signUp: "users/create-user",
    invite: "users/invite-user",
    list: "users/list-users",
    filterUsers: "users/filter-users",
    getAdmin: "users/get-admin",
    forgetPassword: "users/forget-password",
    updatePassword: "users/update-password",
    updateUserPlan: "users/update-user-plan",
    removeUser: "users/remove-user",
    editUser: "users/edit-user",
    addSubUser: "users/add-sub-user",
    loginAsAdmin: "users/login-as-admin",
    loginAsSuperAdmin: "users/login-as-super-admin",
    createRole: 'users/create-role',
    register: 'users/register-user',
    listRoles: 'users/list-roles',
  },
  serviceRequest: {
    create: "serviceRequest/create-service-request",
    delete: "serviceRequest/remove-service-request",
    get: "serviceRequest/get-service-request",
    view: "serviceRequest/view-service-request",
    close: "serviceRequest/close-service-request",
    user: "serviceRequest/user-service-request",
  },
  customerList: {
    get: "user/get-customer-listing",
    view: "user/view-customer",
  },
  machine: {
    create: "machines/create-machine",
    get: "machines/list-machines",
    delete: "machines/delete-machine",
    update: "machines/edit-machine",
    usermachines: "machines/user-machines",
    singlemachine: "machines/single-machine",
    adminmachines: "machines/admin-machines",
    manufacturer: "machines/get-manufacturer",
  },
  company: {
    create: "company/create-company-info",
    get: "company/get-company-info",
    getCompanies: "company/get-companies",
  },
  invoice: {
    generate: "invoice/generate-invoice",
    viewinvoices: "invoice/view-invoices",
    viewinvoice: "invoice/view-invoice",
  },
  file: {
    upload: "file/upload",
    delete: "file/delete",
  },
  media: {
    add: "media/add-media",
    delete: "media/remove-media",
    get: "media/get-media",
  },
  chat: {
    getConversation: "chat/get-message",
    startConversation: "chat/start-conversation",
    getfiles: "chat/get-files",
  },
  serviceReport: {
    getReport: "serviceReport/get-service-report",
    getReportById: "serviceReport/get-service-report-by-id",
    createReport: "serviceReport/create-service-report",
    reportListing: "serviceReport/service-report-listing",
    removeReport: "serviceReport/remove-service-report",
    userReports: "serviceReport/service-report-user-listing",
  },
  email: {
    sendEmail: "email/send-email",
  },
  dashboard: {
    count: "dashboard/count-creds",
  },
  psi: {
    login: "psi-west-auth/login",
    signUp: "psi-west-auth/sign-up",
    forgetPassword: "psi-west-auth/forget-password",
    updatePassword: "psi-west-auth/reset-password",
  }
};

export default API;
