import actions from './actions';

const {
  CREATE_MACHINE_REQUEST_BEGIN,
  CREATE_MACHINE_REQUEST_SUCCESS,
  GET_USER_MACHINES,
  GET_SINGLE_MACHINE,
  GET_MACHINE_REQUEST_BEGIN,
  GET_MACHINE_REQUEST_SUCCESS,
  GET_ADMIN_MACHINES,
  GET_MANUFACTURER_MACHINES,
  REMOVE_MACHINE_REQUEST_BEGIN,
  REMOVE_MACHINE_REQUEST_SUCCESS,
  UPDATE_MACHINE_REQUEST_BEGIN,
  UPDATE_MACHINE_REQUEST_SUCCESS,
  API_ERROR,
  RESET_ERRORS,
  RESET_API_ERROR,
} = actions;

const initState = {
  loading: false,
  apiError: null,
  error: false,
  machinesList: [],
  userMachines: [],
  userMachine: [],
  refernceMachine: [],
  adminMachines: [],
  manufacturerList: [],
  total: 0,
  message: '',
};

const MachineRequestReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CREATE_MACHINE_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CREATE_MACHINE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: data.message,
        apiError: data.apiError,
        error: true,
      };
    case GET_SINGLE_MACHINE:
      return {
        ...state,
        userMachine: data.machine.userMachine,
        refernceMachine: data.machine.refernceMachine,
      };
    case GET_MANUFACTURER_MACHINES:
      return {
        ...state,
        manufacturerList: data.machines,
      };
    case GET_ADMIN_MACHINES:
      return {
        ...state,
        adminMachines: data.machines,
      };
    case GET_USER_MACHINES:
      return {
        ...state,
        userMachines: data.machines,
      };
    case GET_MACHINE_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_MACHINE_REQUEST_SUCCESS:
      return {
        ...state,
        machinesList: data.machines,
        total: data.total,
        loading: false,
        message: data.message,
        error: data.apiError,
      };
    case REMOVE_MACHINE_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_MACHINE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: data.message,
      };
    case UPDATE_MACHINE_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MACHINE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: data.message,
        apiError: data.apiError,
        error: true,
      };
    case API_ERROR: {
      return {
        ...state,
        apiError:
        err.apiError,
        message: err.message,
        loading: false,
      };
    }
    case RESET_API_ERROR:
      return {
        ...state,
        apiError: null,
        message: '',
        error: false,
        userMachine: [],
        loading: false,
      };
    case RESET_ERRORS:
      return {
        ...state,
        apiError: null,
        message: '',
        error: false,
        loading: false,
      }
    default:
      return state;
  }
};
export default MachineRequestReducer;
