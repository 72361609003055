import { theme, darkTheme, psiTheme } from './theme/themeVariables';

const config = {
  darkMode: false,
  topMenu: false,
  rtl: false,
  theme,
  darkTheme,
  psiTheme
};

export default config;
