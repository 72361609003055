import React from "react";
import { Menu } from "antd";
import { NavLink, useRouteMatch } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import propTypes from "prop-types";
import { getItem } from "../utility/localStorageControl";

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const { isAdmin, email, userType } = getItem("user") || {};
  const { SubMenu } = Menu;
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const PathSplit = mainPath.split("/");
  const mainPathSplit =
    PathSplit[0] === ""
      ? isAdmin
        ? ["services"]
        : ["customerDashboard"]
      : PathSplit;
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : mainPathSplit[0]}`]
      : []
  );
  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };
  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  let keys = [];
  if (!topMenu) {
    keys = [
      `${mainPathSplit.length === 1
        ? mainPathSplit[0]
        : mainPathSplit.length === 2
          ? mainPathSplit[1]
          : mainPathSplit[2]
      }`,
    ];
  }
  const isSuperAdmin = email === process.env.REACT_APP_SUPER_ADMIN;

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      theme={darkMode && "dark"}
      selectedKeys={keys}
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "services"}`]
          : []
      }
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {isSuperAdmin ? (
        <>
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink
                  className="menuItem-iocn"
                  to={`${path}superAdminDashboard`}
                >
                  <FeatherIcon icon="home" />
                </NavLink>
              )
            }
            key="superAdminDashboard"
          >
            <NavLink to={`${path}superAdminDashboard`}>Dashboard</NavLink>
          </Menu.Item>
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}admin`}>
                  <FeatherIcon icon="users" />
                </NavLink>
              )
            }
            key="superAdmin"
          >
            <NavLink to={`${path}admin`}>Admin's</NavLink>
          </Menu.Item>
          <SubMenu
            key=""
            icon={!topMenu && <FeatherIcon icon="settings" />}
            title="Settings"
          >
            <Menu.Item key="info">
            </Menu.Item>
          </SubMenu>
        </>
      ) : (
        <>        <>
          {!isAdmin ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink
                    className="menuItem-iocn"
                    to={`${path}customerDashboard`}
                  >
                    <FeatherIcon icon="home" />
                  </NavLink>
                )
              }
              key="customerDashboard"
            >
              <NavLink to={`${path}customerDashboard`}>Home</NavLink>
            </Menu.Item>
          ) : (
            ""
          )}
          {isAdmin ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}services`}>
                    <FeatherIcon icon="git-pull-request" />
                  </NavLink>
                )
              }
              key="services"
            >
              <NavLink to={`${path}services`}>Dashboard</NavLink>
            </Menu.Item>
          ) : (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}request`}>
                    <FeatherIcon icon="git-pull-request" />
                  </NavLink>
                )
              }
              key="request"
            >
              <NavLink to={`${path}request`}>Requests</NavLink>
            </Menu.Item>
          )}
          {/* Service Report menuItem */}
          {isAdmin ? (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink
                    className="menuItem-iocn"
                    to={`${path}serviceReport`}
                  >
                    <FeatherIcon icon="book-open" />
                  </NavLink>
                )
              }
              key="serviceReport"
            >
              <NavLink to={`${path}serviceReport`}>Service Reports</NavLink>
            </Menu.Item>
          ) : (
            <Menu.Item
              icon={
                !topMenu && (
                  <NavLink
                    className="menuItem-iocn"
                    to={`${path}serviceReport`}
                  >
                    <FeatherIcon icon="book-open" />
                  </NavLink>
                )
              }
              key="serviceReport"
            >
              <NavLink to={`${path}serviceReport`}>Service Reports</NavLink>
            </Menu.Item>
          )}
          <Menu.Item
            icon={
              !topMenu && (
                <NavLink className="menuItem-iocn" to={`${path}equipment`}>
                  <FeatherIcon icon="inbox" />
                </NavLink>
              )
            }
            key="equipment"
          >
            <NavLink to={`${path}equipment`}>Equipment</NavLink>
          </Menu.Item>
          {/* Service Report menuItem */}
          {
            !isAdmin ? <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}invoices`}>
                    <FeatherIcon icon="file-text" />
                  </NavLink>
                )
              }
              key="invoices"
            >
              <NavLink to={`${path}invoices`}>
                Invoices
              </NavLink>
            </Menu.Item> : ''
          }
          {/* Customer File MenuItem */}
          {
            isAdmin ? <Menu.Item
              icon={
                !topMenu && (
                  <NavLink className="menuItem-iocn" to={`${path}files`}>
                    <FeatherIcon icon="folder" />
                  </NavLink>
                )
              }
              key="files"
            >
              <NavLink to={`${path}files`}>
                Customer Files
              </NavLink>
            </Menu.Item> : ''
          }
          <SubMenu key="email" icon={!topMenu && <FeatherIcon icon="settings" />} title="Settings">
            {
              isAdmin ?
                <>
                  <Menu.Item key="users">
                    <NavLink onClick={toggleCollapsed} to={`${path}setting/user`}>
                      <FeatherIcon icon="users" className="sub-menu-icon" />
                      Users
                    </NavLink>
                  </Menu.Item>
                </> :
                <Menu.Item key="info">
                  <NavLink onClick={toggleCollapsed} to={`${path}setting/info`}>
                    <FeatherIcon icon="info" className="sub-menu-icon" />
                    Acc & Company info
                  </NavLink>
                </Menu.Item>
            }
          </SubMenu>
        </></>
      )}

    </Menu >
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
