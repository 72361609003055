const actions = {
  GET_CUSTOMER_LIST_BEGIN: 'GET_CUSTOMER_LIST_BEGIN',
  GET_CUSTOMER_LIST_SUCCESS: 'GET_CUSTOMER_LIST_SUCCESS',
  VIEW_CUSTOMER_BEGIN: 'VIEW_CUSTOMER_BEGIN',
  VIEW_CUSTOMER_SUCCESS: 'VIEW_CUSTOMER_SUCCES',

  API_ERROR: 'API_ERROR',
  getCustomerListBegin: () => ({
    type: actions.GET_CUSTOMER_LIST_BEGIN,
  }),
  getCustomerListSuccess: (data) => ({
    type: actions.GET_CUSTOMER_LIST_SUCCESS,
    data,
  }),
  viewCustomerBegin: () => ({
    type: actions.VIEW_CUSTOMER_BEGIN,
  }),
  viewCustomerSuccess: (data) => ({
    type: actions.VIEW_CUSTOMER_SUCCESS,
    data,
  }),
  apiError: (err) => ({
    type: actions.API_ERROR,
    err,
  }),
};

export default actions;
