import actions from './actions';

const {
  GET_CONVERSATION_BEGIN,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_ERR,
  START_CONVERSATION_BEGIN,
  START_CONVERSATION_SUCCESS,
  START_CONVERSATION_ERR,
} = actions;

const initialState = {
  data: [{
    userName: '',
    email: '',
    time: 1637923121332,
    status: true,
    img: '',
    active: false,
    content: [],
  }],
  members: [],
  total: 0,
  files: [],
  loading: false,
  message: '',
  apiError: null,
};

const getConversation = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CONVERSATION_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case GET_CONVERSATION_SUCCESS:
      return {
        ...initialState,
        data: data.messages.response,
        members: data.messages.participant,
        total: data.messages.total,
        message: data.message,
        apiError: data.apiError,
        loading: false,
      };
    case GET_CONVERSATION_ERR:
      return {
        ...initialState,
        message: err.message,
        apiError: err.apiError,
        loading: false,
      };
    default:
      return state;
  }
};

const startConversation = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case START_CONVERSATION_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case START_CONVERSATION_SUCCESS:
      return {
        ...initialState,
        message: data.message,
        apiError: data.apiError,
        loading: false,
      };
    case START_CONVERSATION_ERR:
      return {
        ...initialState,
        message: err.message,
        apiError: err.apiError,
        loading: false,
      };
    default:
      return state;
  }
};

export { getConversation, startConversation };
