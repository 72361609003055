const actions = {
  FORGET_PASSWORD_BEGIN: "FORGET_PASSWORD_BEGIN",
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
  API_ERROR: "API_ERROR",
  RESET_API_ERROR: "RESET_API_ERROR",
  LOGIN_BEGIN: "LOGIN_BEGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  SIGNUP_BEGIN: "LOGIN_BEGIN",
  SIGNUP_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_BEGIN: "LOGOUT_BEGIN",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  UPDATE_PASSWORD_BEGIN: "UPDATE_PASSWORD_BEGIN",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  forgetPasswordBegin: () => ({
    type: actions.FORGET_PASSWORD_BEGIN,
  }),
  forgetPasswordSuccess: (data) => ({
    type: actions.FORGET_PASSWORD_SUCCESS,
    data,
  }),
  loginBegin: () => ({
    type: actions.LOGIN_BEGIN,
  }),
  loginSuccess: (data) => ({
    type: actions.LOGIN_SUCCESS,
    data,
  }),
  signUpBegin: () => ({
    type: actions.SIGNUP_BEGIN,
  }),
  signUpSuccess: (data) => ({
    type: actions.SIGNUP_SUCCESS,
    data,
  }),
  logoutBegin: () => ({
    type: actions.LOGOUT_BEGIN,
  }),
  logoutSuccess: (data) => ({
    type: actions.LOGOUT_SUCCESS,
    data,
  }),
  updatePasswordBegin: () => ({
    type: actions.UPDATE_PASSWORD_BEGIN,
  }),
  updatePasswordSuccess: (data) => ({
    type: actions.UPDATE_PASSWORD_SUCCESS,
    data,
  }),
  apiError: (err) => ({
    type: actions.API_ERROR,
    err,
  }),
  resetAPIError: () => ({
    type: actions.RESET_API_ERROR,
  }),
};

export default actions;
