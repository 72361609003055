const actions = {
  CREATE_SERVICE_REQUEST_BEGIN: 'CREATE_SERVICE_REQUEST_BEGIN',
  CREATE_SERVICE_REQUEST_SUCCESS: 'CREATE_SERVICE_REQUEST_SUCCESS',

  GET_SERVICE_REQUEST_BEGIN: 'GET_SERVICE_REQUEST_BEGIN',
  GET_SERVICE_REQUEST_SUCCESS: 'GET_SERVICE_REQUEST_SUCCESS',

  VIEW_SERVICE_REQUEST_BEGIN: 'VIEW_SERVICE_REQUEST_BEGIN',
  VIEW_SERVICE_REQUEST_SUCCESS: 'VIEW_SERVICE_REQUEST_SUCCESS',

  USER_SERVICE_REQUEST_BEGIN: 'USER_SERVICE_REQUEST_BEGIN',
  USER_SERVICE_REQUEST_SUCCESS: 'USER_SERVICE_REQUEST_SUCCESS',

  REMOVE_SERVICE_REQUEST_BEGIN: 'REMOVE_SERVICE_REQUEST_BEGIN',
  REMOVE_SERVICE_REQUEST_SUCCESS: 'REOMVE_SERVICE_REQUEST_SUCCESS',

  RESET_API_ERROR: 'RESET_API_ERROR',
  API_ERROR: 'API_ERROR',

  createServiceRequestBegin: () => ({
    type: actions.CREATE_SERVICE_REQUEST_BEGIN,
  }),
  createServiceRequestSuccess: (data) => ({
    type: actions.CREATE_SERVICE_REQUEST_SUCCESS,
    data,
  }),
  getServiceRequestBegin: () => ({
    type: actions.GET_SERVICE_REQUEST_BEGIN,
  }),
  getServiceRequestSuccess: (data) => ({
    type: actions.GET_SERVICE_REQUEST_SUCCESS,
    data,
  }),
  userServiceRequestBegin: () => ({
    type: actions.USER_SERVICE_REQUEST_BEGIN,
  }),
  userServiceRequestSuccess: (data) => ({
    type: actions.USER_SERVICE_REQUEST_SUCCESS,
    data,
  }),
  viewServiceRequestBegin: () => ({
    type: actions.VIEW_SERVICE_REQUEST_BEGIN,
  }),
  viewServiceRequestSuccess: (data) => ({
    type: actions.VIEW_SERVICE_REQUEST_SUCCESS,
    data,
  }),
  removeServiceRequestBegin: () => ({
    type: actions.REMOVE_SERVICE_REQUEST_BEGIN,
  }),
  removeServiceRequestSuccess: (data) => ({
    type: actions.REMOVE_SERVICE_REQUEST_SUCCESS,
    data,
  }),
  resetAPIError: () => ({
    type: actions.RESET_API_ERROR,
  }),
  apiError: (err) => ({
    type: actions.API_ERROR,
    err,
  }),
};

export default actions;
