const actions = {
  READ_NOTIFICATION_BEGIN: 'READ_NOTIFICATION_BEGIN',
  READ_NOTIFICATION_SUCCESS: 'READ_NOTIFICATION_SUCCESS',
  READ_NOTIFICATION_ERR: 'READ_NOTIFICATION_ERR',

  readNotificationBegin: () => ({
    type: actions.READ_NOTIFICATION_BEGIN,
  }),
  readNotificationSuccess: (data) => ({
    type: actions.READ_NOTIFICATION_SUCCESS,
    data,
  }),
  readNotificationErr: (err) => ({
    type: actions.READ_NOTIFICATION_ERR,
    err,
  }),
};

export default actions;
