import actions from './actions';

const {
  ADD_MEDIA_BEGIN,
  ADD_MEDIA_SUCCESS,
  GET_MEDIA_BEGIN,
  GET_MEDIA_SUCCESS,
  REMOVE_MEDIA_BEGIN,
  REMOVE_MEDIA_SUCCESS,
  API_ERROR,
} = actions;

const initialState = {
  media: {},
  loading: false,
  message: '',
  apiError: null,
};

const mediaReducer = (state = initialState, action) => {
  const {
    type,
    data,
    err,
  } = action;
  switch (type) {
    case ADD_MEDIA_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case ADD_MEDIA_SUCCESS:
      return {
        ...initialState,
        loading: false,
        apiError: data.apiError,
        message: data.message,
      };
    case GET_MEDIA_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case GET_MEDIA_SUCCESS:
      return {
        ...initialState,
        media: data.customerFiles,
        loading: false,
        apiError: data.apiError,
        message: data.message,
      };
    case REMOVE_MEDIA_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case REMOVE_MEDIA_SUCCESS:
      return {
        ...initialState,
        media: data.customerFiles,
        loading: false,
        apiError: data.apiError,
        message: data.message,
      };
    case API_ERROR: {
      return {
        ...state,
        error: err.apiError,
        message: err.message,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default mediaReducer;
