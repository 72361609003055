import React, { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from "react-hot-loader/root";
import { Provider, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { ConfigProvider } from "antd";
import Cookies from "js-cookie";
import store from "./redux/store";
import Admin from "./routes/admin";
import User from "./routes/user";
import SuperAdmin from "./routes/superAdmin";
import Auth from "./routes/auth";
import "./static/css/style.css";
import config from "./config/config";
import ProtectedRoute from "./components/utilities/protectedRoute";
import { getItem } from "./utility/localStorageControl";

const { theme, psiTheme } = config;

const ProviderConfig = () => {
  const { rtl, topMenu, darkMode } = useSelector((state) => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });
  const isLoggedIn = Cookies.get("logedIn") || false;
  const user = getItem("user");
  const isAdmin = user?.isAdmin || false;
  const isSuperAdmin = user?.email === process.env.REACT_APP_SUPER_ADMIN
  const token = getItem("accessToken");
  const isLogin = isLoggedIn && token;
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
      <ThemeProvider theme={user?.theme === "psi"? {...psiTheme,rtl, topMenu, darkMode} :{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLogin ? (
            <Auth />
          ) : isSuperAdmin ? (
            <ProtectedRoute path="/" component={SuperAdmin} />
          ) : isAdmin ? (
            <ProtectedRoute path="/" component={Admin} />
          ) : (
            <ProtectedRoute path="/" component={User} />
          )}
          {isLogin &&
            (path === process.env.PUBLIC_URL ||
              path === `${process.env.PUBLIC_URL}/`) && <Redirect to="/" />}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
