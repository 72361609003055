import actions from './actions';

const { 
  CREATE_SERVICE_REQUEST_BEGIN,
  CREATE_SERVICE_REQUEST_SUCCESS,
  GET_SERVICE_REQUEST_BEGIN,
  GET_SERVICE_REQUEST_SUCCESS,
  USER_SERVICE_REQUEST_BEGIN,
  USER_SERVICE_REQUEST_SUCCESS, 
  VIEW_SERVICE_REQUEST_BEGIN,
  VIEW_SERVICE_REQUEST_SUCCESS,
  REMOVE_SERVICE_REQUEST_BEGIN,
  REMOVE_SERVICE_REQUEST_SUCCESS,
  RESET_API_ERROR,
  API_ERROR 
} = actions;

const initState = {
  loading: false,
  error: false,
  addRequestError: false,
  machineData: {},
  serviceRequestList: [],
  refernceMachine: [],
  userServiceRequestList: [],
  requestData: {},
  message: ''
};

const ServiceRequestReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CREATE_SERVICE_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CREATE_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        serviceRequestList: data.user,
        message: data.message,
        error: data.apiError,
        addRequestError: true,
      };
    case GET_SERVICE_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        serviceRequestList: data.requestsData,
        loading: false,
        message: data.message
      };
    case REMOVE_SERVICE_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message:data.message
      }  
    case VIEW_SERVICE_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        requestData: data.user,
        machineData: data.machine,
        refernceMachine: data.refernceMachine,
        message:data.message
      };  
    case USER_SERVICE_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        userServiceRequestList: data.user,
        message:data.message
      }  
    case API_ERROR: {
      return { 
        ...state, 
        error: err.apiError, 
        message: err.message, 
        loading: false 
      };
    }
    case RESET_API_ERROR:
      return { 
        ...state, 
        addRequestError: false,
        error: false,
        message: ''
      };
    default:
      return state;
  }
};
export default ServiceRequestReducer;
