import actions from './actions';

const {
  UPLOAD_FILE_BEGIN,
  UPLOAD_FILE_SUCCESS,
  DELETE_FILE_BEGIN,
  DELETE_FILE_SUCCESS,
  UPDATE_PROGRESS,
  API_ERROR,
} = actions;

const initState = {
  key: '',
  error: null,
  message: '',
  loading: false,
  progress: 0,
};

const uploadReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPLOAD_FILE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        key: data.key,
        error: false,
        message: 'success',
        loading: false,
      };
    case DELETE_FILE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        error: data.apiError,
        message: data.message,
        loading: false,
      };
    case UPDATE_PROGRESS:
      return {
        ...state,
        progress: data,
      };
    case API_ERROR:
      return {
        ...state,
        error: err.apiError,
        message: err.message,
      };
    default:
      return state;
  }
};
export default uploadReducer;
