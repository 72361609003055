import actions from './actions';

const {
  CREATE_COMPANYINFO_REQUEST_BEGIN,
  CREATE_COMPANYINFO_REQUEST_SUCCESS,
  GET_COMPANIES_BEGIN,
  GET_COMPANIES_SUCCESS,
  GET_COMPANY_INFO_BEGIN,
  GET_COMPANY_INFO_SUCCESS,
  API_ERROR,
  RESET_API_ERROR,
} = actions;

const initState = {
  loading: false,
  createLoading: null,
  apiError: false,
  companyList: [],
  companyInfo: {},
  total: 0,
  message: '',
};

const CompanyInfoReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CREATE_COMPANYINFO_REQUEST_BEGIN:
      return {
        ...state,
        createLoading: true,
      };
    case CREATE_COMPANYINFO_REQUEST_SUCCESS:
      return {
        ...state,
        message: data.message,
        apiError: data.apiError,
        createLoading: false,
      };
    case GET_COMPANIES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companyList: data.companies,
        message: data.message,
        apiError: data.apiError,
        loading: false,
      };
    case GET_COMPANY_INFO_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        companyInfo: data.company,
        message: data.message,
        apiError: data.apiError,
        loading: false,
      };
    case API_ERROR: {
      return {
        ...state,
        apiError: err.apiError,
        message: err.message,
        loading: false,
      };
    }
    case RESET_API_ERROR:
      return {
        ...state,
        apiError: null,
        message: '',
        companyInfo: {},
        loading: false,
      };
    default:
      return state;
  }
};
export default CompanyInfoReducer;
