const actions = {
  GENERATE_INVOICE: 'GENERATE_INVOICE',
  VIEW_INVOICES_BEGIN: 'VIEW_INVOICES_BEGIN',
  VIEW_INVOICES_SUCCESS: 'VIEW_INVOICES_SUCCESS',
  VIEW_INVOICE: 'VIEW_INVOICE',

  API_ERRORS: 'API_ERRORS',
  RESET_API_ERRORS: 'RESET_API_ERRORS',

  generateInvoice: (data) => ({
    type: actions.GENERATE_INVOICE,
    data,
  }),
  viewInvoicesBegin: () => ({
    type: actions.VIEW_INVOICES_BEGIN,
  }),
  viewInvoicesSuccess: (data) => ({
    type: actions.VIEW_INVOICES_SUCCESS,
    data,
  }),
  viewInvoice: (data) => ({
    type: actions.VIEW_INVOICE,
    data,
  }),
  apiErrors: (err) => ({
    type: actions.API_ERRORS,
    err,
  }),
  resetAPIErrors: () => ({
    type: actions.RESET_API_ERRORS,
  }),
};

export default actions;
