import React from 'react';
import propTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getItem } from '../../utility/localStorageControl';

const ProtectedRoute = ({ component, path }) => {
  const isLoggedIn = Cookies.get('logedIn') || false;
  const { isAdmin } = getItem('user') || '';
  if (isLoggedIn) {
    if (isAdmin) {
      return <Route component={component} path={path} />;
    } 
    // else if (isEmpty(planType)) {
    //   return <Route component={Plan} path={path} />;
    // }
    else {
      return <Route component={component} path={path} />;
    }
  } else {
    return <Redirect to="/" />
  }
// return isLoggedIn ? <Route component={component} path={path} /> : <Redirect to="/" />;
};

ProtectedRoute.propTypes = {
  component: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
};

export default ProtectedRoute;
