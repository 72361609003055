import Cookies from 'js-cookie';
import actions from './actions';

const {
  FORGET_PASSWORD_BEGIN,
  FORGET_PASSWORD_SUCCESS,
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  SIGNUP_BEGIN,
  SIGNUP_SUCCESS,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  API_ERROR,
  RESET_API_ERROR,
  UPDATE_PASSWORD_BEGIN,
  UPDATE_PASSWORD_SUCCESS
} = actions;

const initState = {
  login: Cookies.get('logedIn'),
  admin: {},
  user: {},
  userList: [],
  total: 0,
  userCompaniesList: [],
  loading: false,
  spinLoading: false,
  apiError: false,
  errorMessage: '',
};

const psiUserReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FORGET_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        apiError: data.apiError,
        loading: false,
      };
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: data.user,
        loading: false,
      };
    case SIGNUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        // apiError: data.apiError,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case API_ERROR:
      return {
        ...state,
        apiError: err.apiError,
        errorMessage: err.message,
        loading: false,
        spinLoading: false,
      };
    case RESET_API_ERROR:
      return {
        ...state,
        apiError: false,
        errorMessage: '',
        loading: false,
        spinLoading: false,
      };
    case UPDATE_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        apiError: data.apiError,
        loading: false,
      };
    default:
      return state;
  }
};

export default psiUserReducer;
