const actions = {
  CREATE_SERVICE_REPORT_BEGIN: 'CREATE_SERVICE_REPORT_BEGIN',
  CREATE_SERVICE_REPORT_SUCCESS: 'CREATE_SERVICE_REPORT_SUCCESS',

  GET_SERVICE_REPORT_BEGIN: 'GET_SERVICE_REPORT_BEGIN',
  GET_SERVICE_REPORT_SUCCESS: 'GET_SERVICE_REPORT_SUCCESS',

  GET_SERVICE_REPORT_BY_ID_BEGIN: 'GET_SERVICE_REPORT_BY_ID_BEGIN',
  GET_SERVICE_REPORT_BY_ID_SUCCESS: 'GET_SERVICE_REPORT_BY_ID_SUCCESS',

  SERVICE_REPORT_LISTING_BEGIN: 'SERVICE_REPORT_LISTING_BEGIN',
  SERVICE_REPORT_LISTING_SUCCESS: 'SERVICE_REPORT_LISTING_SUCCESS',

  REMOVE_SERVICE_REPORT_BEGIN: 'REMOVE_SERVICE_REPORT_BEGIN',
  REMOVE_SERVICE_REPORT_SUCCESS: 'REMOVE_SERVICE_REPORT_SUCCESS',

  RESET_API_ERROR: 'RESET_API_ERROR',
  API_ERROR: 'API_ERROR',

  createServiceReportBegin: () => ({
    type: actions.CREATE_SERVICE_REPORT_BEGIN,
  }),
  createServiceReportSuccess: (data) => ({
    type: actions.CREATE_SERVICE_REPORT_SUCCESS,
    data,
  }),
  getServiceReportBegin: () => ({
    type: actions.GET_SERVICE_REPORT_BEGIN,
  }),
  getServiceReportSuccess: (data) => ({
    type: actions.GET_SERVICE_REPORT_SUCCESS,
    data,
  }),
  getServiceReportByIdBegin: () => ({
    type: actions.GET_SERVICE_REPORT_BY_ID_BEGIN,
  }),
  getServiceReportByIdSuccess: (data) => ({
    type: actions.GET_SERVICE_REPORT_BY_ID_SUCCESS,
    data,
  }),
  serviceReportListingBegin: () => ({
    type: actions.SERVICE_REPORT_LISTING_BEGIN,
  }),
  serviceReportListingSuccess: (data) => ({
    type: actions.SERVICE_REPORT_LISTING_SUCCESS,
    data,
  }),
  removeServiceReportBegin: () => ({
    type: actions.REMOVE_SERVICE_REPORT_BEGIN,
  }),
  removeServiceReportSuccess: (data) => ({
    type: actions.REMOVE_SERVICE_REPORT_SUCCESS,
    data,
  }),
  resetAPIError: () => ({
    type: actions.RESET_API_ERROR,
  }),
  apiError: (err) => ({
    type: actions.API_ERROR,
    err,
  }),
};

export default actions;
