const actions = {
  GET_CONVERSATION_BEGIN: 'GET_CONVERSATION_BEGIN',
  GET_CONVERSATION_SUCCESS: 'GET_CONVERSATION_SUCCESS',
  GET_CONVERSATION_ERR: 'GET_CONVERSATION_ERR',

  SINGLE_CHAT_BEGIN: 'SINGLE_CHAT_BEGIN',
  SINGLE_CHAT_SUCCESS: 'SINGLE_CHAT_SUCCESS',
  SINGLE_CHAT_ERR: 'SINGLE_CHAT_ERR',

  START_CONVERSATION_BEGIN: 'START_CONVERSATION_BEGIN',
  START_CONVERSATION_SUCCESS: 'START_CONVERSATION_SUCCESS',
  START_CONVERSATION_ERR: 'START_CONVERSATION_ERR',

  singleChatBegin: () => ({
    type: actions.SINGLE_CHAT_BEGIN,
  }),

  singleChatSuccess: (data) => ({
    type: actions.SINGLE_CHAT_SUCCESS,
    data,
  }),

  singleChatErr: (err) => ({
    type: actions.SINGLE_CHAT_ERR,
    err,
  }),
  startConversationBegin: () => ({
    type: actions.START_CONVERSATION_BEGIN,
  }),

  startConversationSuccess: (data) => ({
    type: actions.START_CONVERSATION_SUCCESS,
    data,
  }),

  startConversationErr: (err) => ({
    type: actions.START_CONVERSATION_ERR,
    err,
  }),

  getConversationBegin: () => ({
    type: actions.GET_CONVERSATION_BEGIN,
  }),

  getConversationSuccess: (data) => ({
    type: actions.GET_CONVERSATION_SUCCESS,
    data,
  }),

  getConversationErr: (err) => ({
    type: actions.GET_CONVERSATION_ERR,
    err,
  }),

};

export default actions;
