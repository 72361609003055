import actions from './actions';

const { 
  SEND_EMAIL_BEGIN,
  SEND_EMAIL_SUCCESS,
  API_ERROR 
} = actions;

const initState = {
  loading: false,
  error: false,
  message: ''
};

const EmailReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SEND_EMAIL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        message: data.message,
        error: data.apiError,
      };
    case API_ERROR: {
      return { 
        ...state, 
        error: err.apiError, 
        message: err.message, 
        loading: false 
      };
    }
    default:
      return state;
  }
};
export default EmailReducer;
