import actions from './actions';

const {
  GET_CUSTOMER_LIST_BEGIN,
  GET_CUSTOMER_LIST_SUCCESS,
  VIEW_CUSTOMER_BEGIN,
  VIEW_CUSTOMER_SUCCESS,
  API_ERROR,
} = actions;

const initState = {
  loading: false,
  error: null,
  customerData: [],
  specificCustomerData: {},
  message: '',
};

const CustomerListReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_CUSTOMER_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        customerData: data.customer,
        loading: false,
        message: data.message,
      };
    case VIEW_CUSTOMER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_CUSTOMER_SUCCESS:
      return {
        ...state,
        specificCustomerData: data.customer,
        loading: false,
        message: data.message,
      };
    case API_ERROR: {
      return {
        ...state,
        error: err.apiError,
        message: err.message || 'Something went wrong',
        loading: false,
      };
    }
    default:
      return state;
  }
};
export default CustomerListReducer;
