import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Dashboard = lazy(() => import('../../container/customerDashboard/index'));
const Machines = lazy(() => import('../../container/machines'));
const Conversation = lazy(() => import('../../container/chat/ChatApp'));
const CreateReport = lazy(() => import('../../container/serviceReport/createReport'));
const ServiceReport = lazy(() => import('../../container/serviceReport'));
const Request = lazy(() => import('../../container/request'));
const AddRequest = lazy(() => import('../../container/request/component/addRequest'));
const ComapnyInfo = lazy(() => import('../../container/setting/component/companyInfo'));
const MachinesUpdate = lazy(() => import('../../container/machines/component/update'));
const MachinesEdit = lazy(() => import('../../container/machines/component/add'));
const Invoices = lazy(() => import('../../container/invoices/index'));
const GetInvoices = lazy(() => import('../../container/invoices/invoice'));
const NotFound = () => <Redirect to="/" />;
// const NotFound = lazy(() => import("../../container/pages/404"));

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/`} component={Dashboard} />
      <Route exact path={`${path}customerDashboard`} component={Dashboard} />
      <Route exact path={`${path}serviceReport/:ticketId`} component={CreateReport} />
      <Route exact path={`${path}serviceReport`} component={ServiceReport} />
      <Route exact path={`${path}conversation/:id`} component={Conversation} />
      <Route exact path={`${path}equipment`} component={Machines} />
      <Route exact path={`${path}invoices`} component={Invoices} />
      <Route exact path={`${path}invoices/detail/:id`} component={GetInvoices} />
      <Route exact path={`${path}equipment/add`} component={MachinesEdit} />
      <Route exact path={`${path}equipment/update/:id`} component={MachinesUpdate} />
      <Route exact path={`${path}request`} component={Request} />
      <Route exact path={`${path}request/addRequest`} component={AddRequest} />
      <Route exact path={`${path}setting/info`} component={ComapnyInfo} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default DashboardRoutes;
