import React from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { Popover } from '../../popup/popup';
import { logOut } from '../../../redux/users/actionCreator';
import Heading from '../../heading/heading';
import { getItem } from '../../../utility/localStorageControl';
import { Impersonate } from '../auth-info/impersonate'
import { logOutPSI } from '../../../redux/auth/actionCreator';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AuthInfo = () => {
  const { name, userType } = getItem('user') || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const SignOut = e => {
    e.preventDefault();
    if(userType === 'PSI-WEST') {
      dispatch(logOutPSI(history));
    }else {
      dispatch(logOut());
    }
  };
  const secondaryUser = getItem('secondaryAccessToken')
  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <FeatherIcon icon="user" />
          <figcaption>
            <Heading as="h5">{name ? name : 'Admin'}</Heading>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li> */}
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li> */}
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li> */}
          {/* <li>
            <Link to="#">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <><InfoWraper>
      <div className="nav-author">
        {secondaryUser && <Impersonate />}
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          </Link>
        </Popover>
      </div>
    </InfoWraper></>

  );
};

export default AuthInfo;
