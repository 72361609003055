import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

const EmailConfirmation = lazy(() => import('../container/profile/authentication/overview/EmailConfirmation'));
const ResetPassword = lazy(() => import('../container/profile/authentication/overview/ResetPassword'));
const UpdatePassword = lazy(() => import('../container/profile/authentication/overview/UpdatePassword'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/SignUp'));
const Register = lazy(() => import('../container/profile/authentication/overview/Register'));
const SignIn = lazy(() => import('../container/profile/authentication/overview/SignIn'));
// PSI imports
const Login = lazy(() => import('../container/profile/psiWest/LogIn'));
const PsiSignUp = lazy(() => import('../container/profile/psiWest/SignUp'));
const Forget = lazy(() => import('../container/profile/psiWest/Forget'));
const CheckEmail = lazy(() => import('../container/profile/psiWest/CheckEmail'));
const PsiResetPassword = lazy(() => import('../container/profile/psiWest/ResetPassword'));
const ResetSuccess = lazy(() => import('../container/profile/psiWest/PasswordReset'));


const NotFound = lazy(() => import('../container/pages/404'));

const FrontendRoutes = () => (
  <Suspense
    fallback={(
      <div className="spin">
        <Spin />
      </div>
    )}
  >
    <Switch>
      <Route exact path="/email-confirmation" component={EmailConfirmation} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/update-password/:token" component={UpdatePassword} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/signin" component={SignIn} />
      {/* Psi routs */}
      <Route exact path="/psi-west/login" component={Login} />
      <Route exact path="/psi-west/sign-up" component={PsiSignUp} />
      <Route exact path="/psi-west/forget-password" component={Forget} />
      <Route exact path="/psi-west/check-email" component={CheckEmail} />
      <Route exact path="/psi-west/reset-password/:token" component={PsiResetPassword} />
      <Route exact path="/psi-west/password-reset" component={ResetSuccess} />
      <Redirect from="/" to="/signin" />
      <Route path="*" component={NotFound} />


    </Switch>
  </Suspense>
);

export default AuthLayout(FrontendRoutes);
