const actions = {
  READ_MESSAGE_BEGIN: 'READ_MESSAGE_BEGIN',
  READ_MESSAGE_SUCCESS: 'READ_MESSAGE_SUCCESS',
  READ_MESSAGE_ERR: 'READ_MESSAGE_ERR',

  readMessageBegin: () => ({
    type: actions.READ_MESSAGE_BEGIN,
  }),
  readMessageSuccess: (data) => ({
    type: actions.READ_MESSAGE_SUCCESS,
    data,
  }),
  readMessageErr: (err) => ({
    type: actions.READ_MESSAGE_ERR,
    err,
  }),
};

export default actions;
