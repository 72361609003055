const actions = {
    COUNT_BEGIN: "COUNT_BEGIN",
    COUNT_SUCCESS: "COUNT_SUCCESS",
    API_ERROR: "API_ERROR",
    countSuccess: (data) => ({
        type: actions.COUNT_SUCCESS,
        data,
    }),
    countBegin: () => ({
        type: actions.COUNT_BEGIN,
    }),
    apiError: (err) => ({
        type: actions.API_ERROR,
        err,
    }),
};

export default actions;
