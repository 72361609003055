const actions = {
  SEND_EMAIL_BEGIN: 'SEND_EMAIL_BEGIN',
  SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',

  API_ERROR: 'API_ERROR',

  sendEmailBegin: () => ({
    type: actions.SEND_EMAIL_BEGIN,
  }),
  sendEmailSuccess: (data) => ({
    type: actions.SEND_EMAIL_SUCCESS,
    data,
  }),
  apiError: (err) => ({
    type: actions.API_ERROR,
    err,
  }),
};

export default actions;
