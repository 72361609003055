import Cookies from 'js-cookie';
import actions from './actions';

const {
  INVITE_BEGIN,
  INVITE_SUCCESS,
  FORGET_PASSWORD_BEGIN,
  FORGET_PASSWORD_SUCCESS,
  GET_ADMIN_BEGIN,
  GET_ADMIN_SUCCESS,
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  SIGNUP_BEGIN,
  SIGNUP_SUCCESS,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  API_ERROR,
  RESET_API_ERROR,
  LIST_USERS_BEGIN,
  LIST_USERS_SUCCESS,
  UPDATE_PASSWORD_BEGIN,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_USER_PLAN_BEGIN,
  UPDATE_USER_PLAN_SUCCESS,
  REMOVE_USER_BEGIN,
  REMOVE_USER_SUCCESS,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  ADD_SUB_USER_BEGIN,
  ADD_SUB_USER_SUCCESS,
  FILTER_USERS_BEGIN,
  FILTER_USERS_SUCCESS,
  LOGIN_AS_ADMIN_BEGIN,
  LOGIN_AS_ADMIN_SUCCESS,
} = actions;

const initState = {
  login: Cookies.get('logedIn'),
  admin: {},
  user: {},
  userList: [],
  total: 0,
  userCompaniesList: [],
  loading: false,
  spinLoading: false,
  apiError: false,
  errorMessage: '',
};

const userReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case INVITE_BEGIN:
      return {
        ...state,
        spinLoading: true,
      };
    case INVITE_SUCCESS:
      return {
        ...state,
        apiError: false,
        errorMessage: 'success',
        spinLoading: false,
      };
    case LIST_USERS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LIST_USERS_SUCCESS: {
      const userCompanies = data.usersData.users.map((item) => item.manufacturer);
      return {
        ...state,
        userList: data.usersData.users,
        total: data.usersData.total,
        userCompaniesList: userCompanies,
        loading: false,
      };
    }
    case FORGET_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        apiError: data.apiError,
        loading: false,
      };
    case GET_ADMIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_ADMIN_SUCCESS:
      return {
        ...state,
        admin: data.admin,
        loading: false,
      };
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: data.user,
        loading: false,
      };
    case SIGNUP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        // apiError: data.apiError,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case API_ERROR:
      return {
        ...state,
        apiError: err.apiError,
        errorMessage: err.message,
        loading: false,
        spinLoading: false,
      };
    case RESET_API_ERROR:
      return {
        ...state,
        apiError: false,
        errorMessage: '',
        loading: false,
        spinLoading: false,
      };
    case UPDATE_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        apiError: data.apiError,
        loading: false,
      };
    case UPDATE_USER_PLAN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_PLAN_SUCCESS:
      return {
        ...state,
        apiError: data.apiError,
        loading: false,
      };
    case REMOVE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_USER_SUCCESS:
      return {
        ...state,
        apiError: data.apiError,
        loading: false,
      };
    case UPDATE_USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        apiError: data.apiError,
        message: data.message,
      };
    case ADD_SUB_USER_BEGIN:
      return {
        ...state,
        spinLoading: true,
      };
    case ADD_SUB_USER_SUCCESS:
      return {
        ...state,
        apiError: false,
        errorMessage: 'success',
        spinLoading: false,
      };
    case FILTER_USERS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FILTER_USERS_SUCCESS: {
      const userCompanies = data.usersData.users.map((item) => item.manufacturer);
      return {
        ...state,
        userList: data.usersData.users,
        total: data.usersData.total,
        userCompaniesList: userCompanies,
        loading: false,
      };
    }
    case LOGIN_AS_ADMIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_AS_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        apiError: data.apiError,
        message: data.message,
      };
    default:
      return state;
  }
};

export default userReducer;
