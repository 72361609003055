const actions = {
  SEARCH_HEADER_BEGIN: 'SEARCH_HEADER_BEGIN',
  SEARCH_HEADER_SUCCESS: 'SEARCH_HEADER_SUCCESS',
  SEARCH_HEADER_ERR: 'SEARCH_HEADER_ERR',

  searchHeaderBegin: () => ({
    type: actions.SEARCH_HEADER_BEGIN,
  }),

  searchHeaderSuccess: (data) => ({
    type: actions.SEARCH_HEADER_SUCCESS,
    data,
  }),

  searchHeaderErr: (err) => ({
    type: actions.SEARCH_HEADER_ERR,
    err,
  }),
};

export default actions;
