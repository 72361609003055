const actions = {
  CREATE_MACHINE_REQUEST_BEGIN: 'CREATE_MACHINE_REQUEST_BEGIN',
  CREATE_MACHINE_REQUEST_SUCCESS: 'CREATE_MACHINE_REQUEST_SUCCESS',

  GET_MACHINE_REQUEST_BEGIN: 'GET_MACHINE_REQUEST_BEGIN',
  GET_MACHINE_REQUEST_SUCCESS: 'GET_MACHINE_REQUEST_SUCCESS',

  VIEW_MACHINE_REQUEST_BEGIN: 'VIEW_MACHINE_REQUEST_BEGIN',
  VIEW_MACHINE_REQUEST_SUCCESS: 'VIEW_MACHINE_REQUEST_SUCCESS',

  REMOVE_MACHINE_REQUEST_BEGIN: 'REMOVE_MACHINE_REQUEST_BEGIN',
  REMOVE_MACHINE_REQUEST_SUCCESS: 'REOMVE_MACHINE_REQUEST_SUCCESS',

  UPDATE_MACHINE_REQUEST_BEGIN: 'UPDATE_MACHINE_REQUEST_BEGIN',
  UPDATE_MACHINE_REQUEST_SUCCESS: 'UPDATE_MACHINE_REQUEST_SUCCESS',

  GET_USER_MACHINES: 'GET_USER_MACHINES',
  GET_SINGLE_MACHINE: 'GET_SINGLE_MACHINE',
  GET_ADMIN_MACHINES: 'GET_ADMIN_MACHINES',
  GET_MANUFACTURER_MACHINES: 'GET_MANUFACTURER_MACHINES',

  API_ERROR: 'API_ERROR',
  RESET_ERRORS: 'RESET_ERRORS',
  RESET_API_ERROR: 'RESET_API_ERROR',

  createMachineRequestBegin: () => ({
    type: actions.CREATE_MACHINE_REQUEST_BEGIN,
  }),
  createMachineRequestSuccess: (data) => ({
    type: actions.CREATE_MACHINE_REQUEST_SUCCESS,
    data,
  }),
  getMachineRequestBegin: () => ({
    type: actions.GET_MACHINE_REQUEST_BEGIN,
  }),
  getMachineRequestSuccess: (data) => ({
    type: actions.GET_MACHINE_REQUEST_SUCCESS,
    data,
  }),
  viewMachineRequestBegin: () => ({
    type: actions.VIEW_MACHINE_REQUEST_BEGIN,
  }),
  viewMachineRequestSuccess: (data) => ({
    type: actions.VIEW_MACHINE_REQUEST_SUCCESS,
    data,
  }),
  removeMachineRequestBegin: () => ({
    type: actions.REMOVE_MACHINE_REQUEST_BEGIN,
  }),
  removeMachineRequestSuccess: (data) => ({
    type: actions.REMOVE_MACHINE_REQUEST_SUCCESS,
    data,
  }),
  updateMachineRequestBegin: () => ({
    type: actions.UPDATE_MACHINE_REQUEST_BEGIN,
  }),
  updateMachineRequestSuccess: (data) => ({
    type: actions.UPDATE_MACHINE_REQUEST_SUCCESS,
    data,
  }),
  getUserSpecificMachines: (data) => ({
    type: actions.GET_USER_MACHINES,
    data,
  }),
  getSpecificMachine: (data) => ({
    type: actions.GET_SINGLE_MACHINE,
    data,
  }),
  getAdminMachinesSuccess: (data) => ({
    type: actions.GET_ADMIN_MACHINES,
    data,
  }),
  getManufacturerMachines: (data) => ({
    type: actions.GET_MANUFACTURER_MACHINES,
    data,
  }),
  apiError: (err) => ({
    type: actions.API_ERROR,
    err,
  }),
  resetErrors: () => ({
    type: actions.RESET_ERRORS,
  }),
  resetAPIError: () => ({
    type: actions.RESET_API_ERROR,
  }),
};

export default actions;
