import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Dashboard = lazy(() => import('../../container/dashboard'));
const Machines = lazy(() => import('../../container/machines'));
const Conversation = lazy(() => import('../../container/chat/ChatApp'));
const CreateReport = lazy(() => import('../../container/serviceReport/createReport'));
const ServiceReport = lazy(() => import('../../container/serviceReport'));
const CustomerFiles = lazy(() => import('../../container/customerFiles/component/index'));
const TeamMember = lazy(() => import('../../container/setting/component/user'));
const Invoices = lazy(() => import('../../container/invoices/index'));
const GetInvoices = lazy(() => import('../../container/invoices/invoice'));
const Request = lazy(() => import('../../container/request/index'));
const CustomerDetail = lazy(() => import('../../container/customerFiles/component/detail'));
const MachinesUpdate = lazy(() => import('../../container/machines/component/update'));
const MachinesEdit = lazy(() => import('../../container/machines/component/add'));
// const CreateRole = lazy(() => import('../../container/setting/component/createRole'));

const NotFound = () => <Redirect to="/" />;
// const NotFound = lazy(() => import("../../container/pages/404"));

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/`} component={Dashboard} />
      <Route exact path={`${path}services`} component={Dashboard} />
      <Route exact path={`${path}serviceReport/:ticketId`} component={CreateReport} />
      <Route exact path={`${path}serviceReport`} component={ServiceReport} />
      <Route exact path={`${path}conversation/:id`} component={Conversation} />
      <Route exact path={`${path}invoices`} component={Invoices} />
      <Route exact path={`${path}invoices/detail/:id`} component={GetInvoices} />
      <Route exact path={`${path}equipment`} component={Machines} />
      <Route exact path={`${path}request`} component={Request} />
      <Route exact path={`${path}equipment/update/:id`} component={MachinesUpdate} />
      <Route exact path={`${path}equipment/add`} component={MachinesEdit} />
      <Route exact path={`${path}files`} component={CustomerFiles} />
      <Route exact path={`${path}files/detail/:id`} component={CustomerDetail} />
      <Route exact path={`${path}setting/user`} component={TeamMember} />
      {/* <Route exact path={`${path}setting/create-role`} component={CreateRole} /> */}
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default DashboardRoutes;
