const actions = {
  CREATE_COMPANYINFO_REQUEST_BEGIN: 'CREATE_COMPANYINFO_BEGIN',
  CREATE_COMPANYINFO_REQUEST_SUCCESS: 'CREATE_COMPANYINFO_SUCCESS',
  GET_COMPANIES_BEGIN: 'GET_COMPANIES_BEGIN',
  GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
  GET_COMPANY_INFO_BEGIN: 'GET_COMPANY_INFO_BEGIN',
  GET_COMPANY_INFO_SUCCESS: 'GET_COMPANY_INFO_SUCCESS',
  API_ERROR: 'API_ERROR',
  RESET_API_ERROR: 'RESET_API_ERROR',

  createCompanyInfoActionBegin: () => ({
    type: actions.CREATE_COMPANYINFO_REQUEST_BEGIN,
  }),
  createCompanyInfoActionSuccess: (data) => ({
    type: actions.CREATE_COMPANYINFO_REQUEST_SUCCESS,
    data,
  }),
  getCompaniesBegin: () => ({
    type: actions.GET_COMPANIES_BEGIN,
  }),
  getCompaniesSuccess: (data) => ({
    type: actions.GET_COMPANIES_SUCCESS,
    data,
  }),
  getCompanyInfoBegin: () => ({
    type: actions.GET_COMPANY_INFO_BEGIN,
  }),
  getCompanyInfoSuccess: (data) => ({
    type: actions.GET_COMPANY_INFO_SUCCESS,
    data,
  }),
  apiError: (err) => ({
    type: actions.API_ERROR,
    err,
  }),
  resetAPIError: () => ({
    type: actions.RESET_API_ERROR,
  }),
};

export default actions;
