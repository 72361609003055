import actions from './actions';

const { 
  CREATE_SERVICE_REPORT_BEGIN,
  CREATE_SERVICE_REPORT_SUCCESS,
  GET_SERVICE_REPORT_BEGIN,
  GET_SERVICE_REPORT_SUCCESS,
  GET_SERVICE_REPORT_BY_ID_BEGIN,
  GET_SERVICE_REPORT_BY_ID_SUCCESS,
  SERVICE_REPORT_LISTING_BEGIN,
  SERVICE_REPORT_LISTING_SUCCESS,
  REMOVE_SERVICE_REPORT_BEGIN,
  REMOVE_SERVICE_REPORT_SUCCESS,
  RESET_API_ERROR,
  API_ERROR 
} = actions;

const initState = {
  loading: false,
  error: null,
  reportData : {},
  message: '',
  apiError: false
};

const ServiceReportReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CREATE_SERVICE_REPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CREATE_SERVICE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: data.message,
        error: data.apiError,
      }
    case GET_SERVICE_REPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SERVICE_REPORT_SUCCESS:
      return {
        ...state,
        reportData: data.array[0],
        loading: false,
        message: data.message,
        apiError: data.apiError
      };
    case GET_SERVICE_REPORT_BY_ID_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SERVICE_REPORT_BY_ID_SUCCESS:
      return {
        ...state,
        reportData: data.data,
        loading: false,
        message: data.message,
        apiError: data.apiError
      };
    case SERVICE_REPORT_LISTING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SERVICE_REPORT_LISTING_SUCCESS:
      return {
        ...state,
        reportData: data.reportsData,
        loading: false,
        message: data.message,
        apiError: data.apiError
      };
    case REMOVE_SERVICE_REPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_SERVICE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: data.message,
        apiError: data.apiError
      };
    case API_ERROR: {
      return { 
        ...state, 
        error: err.apiError, 
        message: err.message, 
        loading: false 
      };
    }
    case RESET_API_ERROR:
      return { 
        ...state, 
        addRequestError: false, 
        message: ''
      };
    default:
      return state;
  }
};
export default ServiceReportReducer;
