const isSuccess = ({ status }) => status >= 200 && status <= 299;
const signUpTOSMessage = 'Creating an account means you’re okay with our Terms of Service and Privacy Policy';
const websiteExpression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
const toPascalCase = (s) => s?.replace(/_/g, ' ').replace(/\w+/g, (w) => w[0].toUpperCase() + w.slice(1).toLowerCase());

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export {
  isSuccess, 
  signUpTOSMessage, 
  toPascalCase, 
  websiteExpression,
  sleep
};
