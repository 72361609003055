import React, { Suspense } from "react";
import { Spin } from "antd";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Dashboard from "./dashboard";
import withAdminLayout from "../../layout/withAdminLayout";

const SuperAdmin = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(SuperAdmin);
