const actions = {
  UPLOAD_FILE_BEGIN: 'UPLOAD_FILE_BEGIN',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  DELETE_FILE_BEGIN: 'DELETE_FILE_BEGIN',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
  UPDATE_PROGRESS: 'UPDATE_PROGRESS',
  API_ERROR: 'API_ERROR',

  uploadFileBegin: () => ({
    type: actions.UPLOAD_FILE_BEGIN,
  }),
  uploadFileSuccess: (data) => ({
    type: actions.UPLOAD_FILE_SUCCESS,
    data,
  }),
  deleteFileBegin: () => ({
    type: actions.DELETE_FILE_BEGIN,
  }),
  deleteFileSuccess: (data) => ({
    type: actions.DELETE_FILE_SUCCESS,
    data,
  }),
  UpdateProgress: (data) => ({
    type: actions.UPDATE_PROGRESS,
    data,
  }),
  apiError: (err) => ({
    type: actions.API_ERROR,
    err,
  }),
};
export default actions;
