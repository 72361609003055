import actions from './actions';

const {
  GENERATE_INVOICE,
  VIEW_INVOICES_BEGIN,
  VIEW_INVOICES_SUCCESS,
  VIEW_INVOICE,
  API_ERRORS,
  RESET_API_ERRORS,
} = actions;

const initialState = {
  loading: false,
  invoiceList: [],
  userInvoiceList: [],
  invoiceData: [],
  user: {},
  total: 0,
  apiError: null,
  message: '',
};

const InvoiceReducers = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GENERATE_INVOICE:
      return {
        ...state,
        invoiceData: data,
        apiError: data.apiError,
        message: data.message,
      };
    case VIEW_INVOICES_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case VIEW_INVOICES_SUCCESS:
      return {
        ...state,
        invoiceList: data.invoices,
        total: data.total,
        apiError: data.apiError,
        message: data.message,
        loading: false,
      };
    case VIEW_INVOICE:
      return {
        ...state,
        userInvoiceList: data.invoice,
        user: data.user,
        apiError: data.apiError,
        message: data.message,
      };
    case API_ERRORS:
      return {
        ...state,
        apiError: err.apiError,
        message: err.message,
      };
    case RESET_API_ERRORS:
      return {
        ...state,
        apiError: null,
        message: '',
      };
    default:
      return state;
  }
};

export default InvoiceReducers;
